.page {
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  max-width: 1000px;
  margin: 0 auto;
}

@keyframes left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.left {
  animation: left 1s ease-in-out;
}

.right {
  animation: right 1s ease-in-out;
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.fadeout {
  animation: fadeout 1s ease-in-out;
  animation-delay: 2s;
}
@keyframes tilt {
  0% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(10deg);
  }
}

.tilt {
  animation: tilt 2s ease-in-out infinite;
  color: rgb(244, 188, 59);
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 auto;
  padding: 1rem 2rem 0 2rem;
  text-align: justify;
  max-width: 700px;
}

span {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  line-height: 1.5;
  text-align: justify;
  max-width: 700px;
  color: rgb(69, 66, 66);
}

h1 {
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
}

button {
  background: #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  padding: 0.5rem 2rem 0.5rem 2rem;
  text-align: center;
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
}

.heading {
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  padding: 1rem 2rem 0 2rem;
  text-align: center;
}

/* Homepage layout */
.homepage {
  .profile_image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    text-align: center;
    margin: 0 auto;
    border-radius: 60% 50% 40% 30%;
  }
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-top: 3rem;
    max-width: 700px;
  }
  &-content {
    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0 0 2rem;

      @media screen and (max-width: 768px) {
        padding: 1rem 0 0 2rem;
      }
    }
  }
}

/* About page layout */
.about {
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  justify-content: center;

  &-journey {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1rem auto;
    @media screen and (max-width: 650px) {
      max-width: 100%;
      padding: 1rem;
    }
    @media screen and (min-width: 650px) {
      max-width: 75%;
      padding: 1rem 2rem 1rem 2rem;
    }
  }
}

/* Skills layout */

.card {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  max-width: 700px;

  .card-header {
    background-color: rgb(218, 215, 215);
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 12px 2px 12px 2px;
    text-align: center;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
    max-width: 700px;
  }

  .card-body {
    padding: 2rem 2rem 2rem 2rem;
  }

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    cursor: grab;
  }
}

/* Contact layout */
@media screen and (min-width: 728px) {
  label {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
    padding: 1rem 2rem 0 2rem;
    text-align: left;
  }

  .card-input {
    box-shadow: 3px 3px 15px 3px rgba(154, 154, 154, 0.1);
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    margin: 2rem 0 0 2rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-align: left;
    border-radius: 1rem;
    width: 20rem;
    border: 1px solid #adacac;
  }

  textarea {
    box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, 0.1);
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 2rem 0 0 0.5rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-align: left;
    border-radius: 1rem;
    height: 8rem;
    align-items: baseline;
    width: 20rem;
    border: 1px solid #8c8b8b;
  }
  .submit_button {
    margin: 1rem 0 0 1rem;
    background-color: black;
    color: white;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    width: 10rem;
    position: relative;
    left: 13rem;
    top: 1rem;
    padding: 0.5rem 0 0.5rem 0;
    border-radius: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 728px) {
  .form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  label {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
    padding: 1rem 2rem 0 0;
    text-align: left;
    position: relative;
    left: -3rem;
  }
  input {
    box-shadow: 3px 3px 15px 3px rgba(188, 188, 188, 0.1);
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    margin: 0 0 0 1rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-align: left;
    border-radius: 0.5rem;
    border: 1px solid #8c8b8b;
  }
  textarea {
    box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, 0.1);
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0 0 0 1rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-align: left;
    border-radius: 0.5rem;
    height: 8rem;
    align-items: baseline;
    border: 1px solid #8c8b8b;
  }
  .submit_button {
    margin: 1rem 0 0 1rem;
    background-color: black;
    color: white;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 700;
  }
  .message {
    margin: 0 0 0 1.5rem;
  }
}
.formbox {
  box-shadow: 14px 14px 14px 14px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

/* Timeline layout */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* Awards layout */
.pelican {
  clip-path: none;
  border: none;
  border-radius: none !important;
}
